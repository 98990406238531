import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { Form } from 'components/Form/Form'
import { navigate, PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import React, { useEffect, useRef, useState } from 'react'
import { addFileToCDN } from './addFileToCDN'
import { CustomModal } from '../../../../../components/common/useModal'
import { getAssignmentData } from 'API/getAssignmentData'
import { useEditableHook } from '../../../../../hooks/useEditableHook'
import { useGetModulesHook } from 'hooks/useGetModulesHook'
import { Switch } from '@material-ui/core'
import { PromiseType } from 'utility-types'
import Loader from 'react-js-loader'

const assignmentDates = [
	{ text: 'Visible From:', name: 'visible_from' },
	{ text: 'Available From:', name: 'available_from' },
	{ text: 'Available Till:', name: 'available_till' },
]

const AddAssignment: React.FC<PageProps> = ({ params: { id }, location }) => {
	const { ShowDetails } = useGetCourseDetails(id)
	const [loading, setLoading] = useState(false)
	const [isNonAssignment, setIsNonAssignment] = useState<string | null>(null)

	let ref = useRef<HTMLButtonElement | null>(null)
	const { edit, elem_id } = useEditableHook({ location })

	const { type_of_course, stable } = useGetCourseDetails(id)
	const { modules } = useGetModulesHook({ course: id, stable, type_of_course })
	useEffect(() => {
		const params = new URLSearchParams(location.search)
		const isNonAssignment: string | null = params.get('assignment_type') as any

		if (isNonAssignment) {
			setIsNonAssignment(isNonAssignment)
		}
	}, [location])

	const [editable, setEditable] = React.useState<any>({})
	React.useEffect(() => {
		if (edit === 'true' && elem_id !== '') {
			const run = async () => {
				const asset = await getAssignmentData(elem_id, 'Teacher')
				if (asset) {
					console.log('asset', asset)
					setEditable(asset)
					if (asset?.cdn?.name)
						setEditable((state: any) => ({ ...state, file_display: (asset?.cdn?.name ?? '') + (asset?.cdn?.file_extension ?? '') }))
				}
			}
			run()
		}
	}, [edit, elem_id])

	// useState with marksType: grades or marks
	const [marksType, setMarksType] = useState<'grades' | 'marks'>('marks')
	return (
		<>
			{ShowDetails}

			<CustomModal
				header={isNonAssignment ? `Add ${isNonAssignment}` : 'Add Assignment'}
				onClick={() => {
					// if (edit === 'true') {
					// 	toast('ToDo', { type: 'info' })
					// 	return
					// }
					// console.log(ref)
					if (ref.current) {
						ref.current.click()
					}
				}}
			>
				Marks
				<Switch
					onChange={(e: any) => {
						if (marksType === 'marks') setMarksType('grades')
						else setMarksType('marks')
					}}
					checked={marksType !== 'marks'}
					color='default'
					inputProps={{ 'aria-label': 'checkbox with default color' }}
				/>
				Grade
				<Form
					editableState={editable}
					// ref={ref}
					getRef={(_ref) => {
						if (_ref) ref = _ref
					}}
					action={async ({ state }) => {
						setLoading(true)
						if (edit === 'true') {
							let state_copy = Object.assign({}, state)

							if (state.file) {
								const resp = await addFileToCDN(state)
								if (resp) {
									state_copy = { ...state_copy, cdn: resp.uid }
									delete state_copy['file']
								}
							}
							if (isNonAssignment) {
								state_copy.cdn = undefined
								state_copy.non_assignment_type = isNonAssignment
							}
							state_copy = { ...state_copy, course: id }
							setLoading(false)
							const assignment = await handleApi<{ uid: string }>(
								axios.patch(
									getUrl('asset/assignment'),
									{
										...state_copy,
									},
									getDefaultOptions()
								)
							)
							if (assignment) navigate(`/course/${id}/activities/assignment/${assignment.uid}`)
							return
						}
						let resp: PromiseType<ReturnType<typeof addFileToCDN>>
						if (state.file) {
							// setLoading(true)
							resp = await addFileToCDN(state)
							setLoading(false)
						}
						if (resp && state.file) {
							const state_copy = Object.assign({}, state)
							delete state_copy['file']

							if (isNonAssignment) {
								state_copy.cdn = undefined
								state_copy.non_assignment_type = isNonAssignment
							}
							console.log(state_copy)
							console.log('upload')
							// setLoading(true)
							const assignment = await handleApi<{ uid: string }>(
								axios.post(
									getUrl('asset/assignment'),
									{
										...state_copy,
										cdn: resp.uid,
										course: id,
										marks_type: marksType,
									},
									getDefaultOptions()
								)
							)
							setLoading(false)

							if (assignment && (assignment as any)?.errors === undefined) {
								navigate(`/course/${id}/activities/assignment/${assignment.uid}`)
							}
						} else {
							if (!state.file) {
								const state_copy = Object.assign({}, state)
								delete state_copy['file']
								console.log(state_copy)
								if (isNonAssignment) {
									state_copy.cdn = undefined

									state_copy.non_assignment_type = isNonAssignment
								}
								setLoading(false)
								const assignment = await handleApi<{ uid: string }>(
									axios.post(
										getUrl('asset/assignment'),
										{
											...state_copy,
											course: id,
											marks_type: marksType,
										},
										getDefaultOptions()
									)
								)
								if (assignment && (assignment as any)?.errors === undefined)
									navigate(`/course/${id}/activities/assignment/${assignment.uid}`)
							}
						}
					}}
					text={isNonAssignment ? `Add ${isNonAssignment}` : 'Add Assignment'}
					fields={[
						{
							type: 'text',
							label: 'Name',
							name: 'name',
						},
						{
							type: 'textarea',
							label: 'Description',
							name: 'description',
						},
						(marksType as string) === 'marks'
							? {
									type: 'number',
									label: 'Marks',
									name: 'marks',
							  }
							: {
									type: 'null',
									label: 'null',
									name: 'null',
							  },
						{
							type: 'switch',
							label: 'Allow multiple submissions',
							name: 'allowMultiple',
						},
						...assignmentDates.map((elem) => ({
							type: 'datetime-local',
							label: elem.text,
							name: elem.name,
						})),
						...(isNonAssignment === null
							? [
									{
										type: 'image',
										name: 'file',
										label: 'Add file',
										optional: edit === 'true',
									},
							  ]
							: []),
						modules.length > 0
							? {
									type: 'select',
									selection: modules,
									name: 'module',
									label: 'Module',
							  }
							: {
									type: 'select',
									selection: [],
									label: '',
									name: '',
							  },
					]}
				></Form>
				{loading && (
					<div>
						<Loader type='spinner-circle' bgColor={'#1266cc'} size={100}></Loader>
					</div>
				)}
			</CustomModal>
		</>
	)
}

export default AddAssignment
